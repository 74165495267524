import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import SquareCard from "../../squareCard/SquareCard"
import { CardWrapper, ContainerMob } from "../../../utils/utils"

const FooterHeaderWrapper = styled.div`
  background: ${props => props.bgColor || props.theme.color.sec1};

  hr {
    margin-bottom: 0;
    margin-top: 0;
    opacity: ${props => props.hrOpacity || 0.8};
    border: 0;
    height: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
`

const FooHeader = styled.div`
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 50%,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0) 100%
  );
`

const FooterLogo = styled.div`
  align-self: end;
  height: 50%;
  width: auto;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  ${down("sm")} {
    display: none;
  }
`

const Filler = styled.div`
  ${down("md")} {
    display: none;
  }
`

const FooterHeader = ({ heading, subHeading, bgColor, hrOpacity }) => {
  return (
    <FooterHeaderWrapper bgColor={bgColor} hrOpacity={hrOpacity}>
      <FooHeader>
        <ContainerMob>
          <StaticImage
            src="../../../assets/images/pfr.jpg"
            alt="Logo"
            placeholder="blurred"
            layout="constrained"
            transformOptions={{ fit: "contain" }}
          />
        </ContainerMob>
        <CardWrapper colGap="0" rowGap="0" mb="0" mt="0" xl="4">
          <FooterLogo>
            <StaticImage
              src="../../../assets/images/logo/logo_white.png"
              alt="Logo"
              placeholder="blurred"
              layout="fixed"
              height={80}
              transformOptions={{ fit: "contain" }}
            />
          </FooterLogo>
          <Filler />
          <SquareCard
            delay=""
            variant="dark"
            name="Oferta"
            link="/oferta"
            opis="Zapraszamy do zapoznania się z ofertą nszych usług."
          />
          <SquareCard
            delay="100"
            variant="base"
            link="/kontakt"
            name={heading}
            opis={subHeading}
          />
        </CardWrapper>
        <ContainerMob>
          <hr />
        </ContainerMob>
      </FooHeader>
    </FooterHeaderWrapper>
  )
}
export default FooterHeader
